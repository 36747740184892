/* src/components/Login.css */

.login__page {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.login__page__left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #47b5ff;
  width: 50vw;
  height: 100vh;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' version='1.1' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:svgjs='http://svgjs.com/svgjs' width='1440' height='1280' preserveAspectRatio='none' viewBox='0 0 1440 1280'%3e%3cg mask='url(%26quot%3b%23SvgjsMask1126%26quot%3b)' fill='none'%3e%3crect width='1440' height='1280' x='0' y='0' fill='url(%23SvgjsLinearGradient1127)'%3e%3c/rect%3e%3cpath d='M1440 0L1062.72 0L1440 576.97z' fill='rgba(255%2c 255%2c 255%2c .1)'%3e%3c/path%3e%3cpath d='M1062.72 0L1440 576.97L1440 831.36L984.49 0z' fill='rgba(255%2c 255%2c 255%2c .075)'%3e%3c/path%3e%3cpath d='M984.49 0L1440 831.36L1440 1063.53L618.04 0z' fill='rgba(255%2c 255%2c 255%2c .05)'%3e%3c/path%3e%3cpath d='M618.04 0L1440 1063.53L1440 1163.32L232.17999999999995 0z' fill='rgba(255%2c 255%2c 255%2c .025)'%3e%3c/path%3e%3cpath d='M0 1280L268.84 1280L0 1025.01z' fill='rgba(0%2c 0%2c 0%2c .1)'%3e%3c/path%3e%3cpath d='M0 1025.01L268.84 1280L542.31 1280L0 539.61z' fill='rgba(0%2c 0%2c 0%2c .075)'%3e%3c/path%3e%3cpath d='M0 539.61L542.31 1280L594.1999999999999 1280L0 437.71000000000004z' fill='rgba(0%2c 0%2c 0%2c .05)'%3e%3c/path%3e%3cpath d='M0 437.71000000000004L594.1999999999999 1280L1116.55 1280L0 372.07000000000005z' fill='rgba(0%2c 0%2c 0%2c .025)'%3e%3c/path%3e%3c/g%3e%3cdefs%3e%3cmask id='SvgjsMask1126'%3e%3crect width='1440' height='1280' fill='white'%3e%3c/rect%3e%3c/mask%3e%3clinearGradient x1='2.78%25' y1='-3.13%25' x2='97.22%25' y2='103.13%25' gradientUnits='userSpaceOnUse' id='SvgjsLinearGradient1127'%3e%3cstop stop-color='rgba(71%2c 181%2c 255%2c 1)' offset='0.01'%3e%3c/stop%3e%3cstop stop-color='rgba(7%2c 115%2c 255%2c 1)' offset='1'%3e%3c/stop%3e%3c/linearGradient%3e%3c/defs%3e%3c/svg%3e");
  background-size: cover;
  background-position: center;
}

.login__page__right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50vw;
}

.login__container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 400px;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}

.login__logo__container {
  color: #212427;
  content: 'logo';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 120px;
  margin-bottom: 30px;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  background-color: #fcfcfc;
}

.login__logo__container::after {
  position: absolute;
}

.logo {
  position: absolute;
  font-size: 6.5rem;
  color: #38bdf8;
}

.login__title {
  font-weight: 500;
  color: #212427;
}

.login__description {
  color: #212427;
  margin-bottom: 80px;
  padding: 0 20px;
  font-size: 0.9rem;
}

.input__group {
  position: relative;
  width: 100%;
}

.input {
  padding: 10px 10px 5px 10px;
  width: 100%;
  border: 1px solid #e2e8f0;
  border-radius: 15px;
  font: inherit;
  color: #212427;
  background-color: #fcfcfc;
}

.input_label {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate(10px, 10px);
  transition: transform 0.25s;
  color: #8f8f8f;
  font-size: 0.9rem;
}

.input:focus + .input_label,
.input:valid + .input_label {
  transform: translate(0px, -25px) scale(0.8);
  color: #38bdf8;
  padding-inline: 0px;
  background-color: transparent;
}

.input:is(:focus, :valid) {
  outline: 2px solid #38bdf8;
  background-color: #fff;
}

.pwd__reset_label_container,
a {
  display: flex;
  width: 100%;
  color: #38bdf8;
  text-decoration: none;
  align-items: flex-start;
  justify-content: flex-end;
  font-size: 0.8rem;
}

.login__btn {
  padding: 15px;
  font-size: 16px;
  margin: 40px 0;
  border: none;
  width: 100%;
  border-radius: 5px;
  color: white;
  background-color: #38bdf8;
}

.powered__description {
  color: #8f8f8f;
}

/* mobile portrait */
@media screen and (max-width: 768px) {
  .login__page__left {
    display: none;
  }
  .login__page__right {
    width: 90vw;
  }
  .login__container {
    width: 100%;
  }
}
